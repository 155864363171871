import { NormalizedCategory } from '@wix/communities-blog-client-common';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

interface Option {
  label: string;
  value: string | undefined;
}

const createController = (({ $widget, $w }) => {
  let categories: Option[] = [];

  const applyCategoriesState = () => {
    const options =
      $widget.props.categoriesToDisplay === 'mainCategory'
        ? [categories[0]]
        : categories;
    $w(selectors.categories).options = categories.length ? options : [];
  };

  return {
    pageReady: async () => $widget.onPropsChanged(applyCategoriesState),
    exports: {
      setCategories: (normalizedCategories: NormalizedCategory[]) => {
        categories = normalizedCategories.map((cat) => ({
          label: cat?.menuLabel,
          value: cat?.slug,
        }));
        applyCategoriesState();
      },
      onCategoryClick: (callback: (slug: string) => Promise<void>) => {
        $w(selectors.categories).onClick(async (event: $w.MouseEvent) => {
          const categoriesClicked = event.target.value;
          const lastCategoryClicked = categoriesClicked.at(-1);
          if (!lastCategoryClicked) {
            throw new Error('No categories clicked');
          }

          callback(lastCategoryClicked);
        });
      },
    },
  };
}) satisfies CreateController;

export type PostCategoriesApi = ReturnType<typeof createController>['exports'];

const selectors = {
  categories: '#categoriesElement',
} as const;

export default model.createController(createController);
