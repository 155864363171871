import { IWixAPI, InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { isEditorOrPreview } from './selectors';

interface GetBaseUrlParams {
  wixCodeApi: IWixAPI;
  initParams: Parameters<InitAppForPageFn>[0];
}

export const getBaseURL = ({ wixCodeApi, initParams }: GetBaseUrlParams) =>
  isEditorOrPreview(wixCodeApi)
    ? initParams.baseUrls.apiBaseUrl.replace(
        '_api/communities-blog-node-api',
        '',
      )
    : wixCodeApi.location.baseUrl;
