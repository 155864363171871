import {
  IWixAPI,
  InitAppForPageFn,
  ViewerScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import { BLOG_POST_PAGE_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { NormalizedCategory } from '@wix/communities-blog-client-common';
import { getSectionPathname } from '../external/common/controller/helpers';
import { Router } from '../external/common/router';
import { resolvePostSlug } from '../external/common/services/slug';
import { NormalizedPost } from '../external/common/types';
import {
  ROUTE_404,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
} from '../external/post-page/constants/routes';
import { customRouteHandler } from '../external/post-page/controller/route-handlers/custom-route-handler';
import { getSectionUrl } from '../external/post-page/services/get-section-url';
import { getFieldsets, isEditorOrPreview } from './selectors';

interface GetPostAndCategoriesParams {
  wixCodeApi: IWixAPI;
  initParams: Parameters<InitAppForPageFn>[0];
  baseURL: string;
  flowAPI: ViewerScriptFlowAPI;
}

interface PostResponse extends NormalizedPost {
  tags: BlogTag[];
}

export const getPostAndCategories = async ({
  wixCodeApi,
  initParams,
  baseURL,
  flowAPI,
}: GetPostAndCategoriesParams) => {
  const editorOrPreview = isEditorOrPreview(wixCodeApi);

  const [{ data }, { data: categories }] = await Promise.all([
    editorOrPreview
      ? getEditorPostRequest({ flowAPI, baseURL })
      : getViewerPostRequest({ wixCodeApi, initParams, baseURL, flowAPI }),
    flowAPI.httpClient.get<NormalizedCategory[]>(
      '_api/communities-blog-node-api/_api/categories?offset=0&size=500&includeTranslations=true&includeSeo=true',
      {
        baseURL,
      },
    ),
  ]);

  return {
    post: editorOrPreview
      ? (data as PostResponse[])[0]
      : (data as PostResponse),
    categories,
  };
};

interface GetViewerPostRequestParams {
  wixCodeApi: IWixAPI;
  initParams: Parameters<InitAppForPageFn>[0];
  baseURL: string;
  flowAPI: ViewerScriptFlowAPI;
}

const getViewerPostRequest = async ({
  wixCodeApi,
  initParams,
  baseURL,
  flowAPI,
}: GetViewerPostRequestParams) => {
  const router = new Router();
  const sectionUrl = await getSectionUrl({
    wixCodeApi,
    instanceId: initParams.instanceId,
    sectionId: BLOG_POST_PAGE_SECTION_ID,
  });
  const pathname = getSectionPathname({ wixCodeApi, sectionUrl });

  router.add(ROUTE_POST);
  router.add(ROUTE_POST_ADVANCED_SLUG);
  router.addCustomRouteHandler(() => customRouteHandler(wixCodeApi));
  router.fallback(ROUTE_404);

  const matchPathResult = await router.matchPath({ pathname });
  const slug = resolvePostSlug(matchPathResult.params) as string;

  return flowAPI.httpClient.get<PostResponse>(
    `_api/communities-blog-node-api/_api/posts/content/${slug}?${getFieldsets()}`,
    {
      baseURL,
    },
  );
};

interface GetEditorPostRequestParams {
  flowAPI: ViewerScriptFlowAPI;
  baseURL: string;
}

const getEditorPostRequest = ({
  flowAPI,
  baseURL,
}: GetEditorPostRequestParams) =>
  flowAPI.httpClient.get<PostResponse[]>(
    `_api/communities-blog-node-api/_api/posts?offset=0&size=1&pinnedFirst=true&featuredOnly=false&${getFieldsets()}`,
    { baseURL },
  );
