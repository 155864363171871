import { MetadataController } from './metadata-controller';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = (({ flowAPI, $w }) => {
  return {
    pageReady: async () => {},
    exports: new MetadataController($w, flowAPI),
  };
}) satisfies CreateController;

export type PostMetadataApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
