import {
  IWixAPI,
  InitAppForPageFn,
  ViewerScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { PostPageRenderModel } from '../external/blocks-widget/blocks-widget.type';
import { getBaseURL } from './base-url';
import { fetchMetadata } from './metadata';
import { getPostAndCategories } from './post';

interface FetchInitialDataParams {
  wixCodeApi: IWixAPI;
  initParams: Parameters<InitAppForPageFn>[0];
  flowAPI: ViewerScriptFlowAPI;
}

export const fetchInitialData = async ({
  wixCodeApi,
  initParams,
  flowAPI,
}: FetchInitialDataParams): Promise<PostPageRenderModel> => {
  const baseURL = getBaseURL({ wixCodeApi, initParams });
  const { post, categories } = await getPostAndCategories({
    wixCodeApi,
    initParams,
    baseURL,
    flowAPI,
  });

  const metadata = await fetchMetadata({
    flowAPI,
    baseURL,
    postId: post.id,
  });

  return {
    post,
    categories,
    tags: post.tags,
    counters: {
      viewCount: metadata.viewCount,
      likeCount: metadata.likeCount,
      commentsCount: metadata.totalComments,
      isLiked: metadata.isLiked,
    },
  };
};
