import type { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { formatDate, formatDateICU } from '@wix/communities-blog-client-common';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';

interface PostMetadata {
  writer: {
    name: string;
    slug: string;
    memberId: string;
  };
  firstPublishedDate: string;
  timeToRead: number;
  lastPublishedDate: string;
}

export class MetadataController {
  private state: PostMetadata = {
    writer: {
      name: '',
      slug: '',
      memberId: '',
    },
    firstPublishedDate: '',
    timeToRead: 0,
    lastPublishedDate: '',
  };

  private readonly ui = {
    writer: this.$w('#text1'),
    publishedDate: this.$w('#text5'),
    timeToRead: this.$w('#text2'),
    lastPublishedDate: this.$w('#text3'),
  };

  private readonly facade = new BlocksWidgetFacade(this.flowAPI);

  constructor(private $w: I$W, private flowAPI: PlatformControllerFlowAPI) {}

  initialize = async (initialState: MetadataController['state']) => {
    this.setState(initialState);
    this.ui.writer.onClick(this.handleWriterClick.bind(this));
  };

  private formatPublishedDate(date: string) {
    return formatDate({
      date,
      lng: this.flowAPI.controllerConfig.wixCodeApi.site.language,
      timezone: this.flowAPI.controllerConfig.wixCodeApi.site.timezone,
    });
  }

  private formatTimeToRead(time: number) {
    return `${time} min read`;
  }

  private formatUpdated(date: string) {
    const formattedDate = formatDateICU({
      date,
      lng: this.flowAPI.controllerConfig.wixCodeApi.site.language,
      rtfFormatter: '',
      timezone: this.flowAPI.controllerConfig.wixCodeApi.site.timezone,
    });
    return `Updated: ${formattedDate}`;
  }

  private setState(nextState: MetadataController['state']) {
    this.ui.writer.text = nextState.writer.name;
    this.ui.publishedDate.text = this.formatPublishedDate(
      nextState.firstPublishedDate,
    );
    this.ui.timeToRead.text = this.formatTimeToRead(nextState.timeToRead);
    this.ui.lastPublishedDate.text = this.formatUpdated(
      nextState.lastPublishedDate,
    );

    this.state = nextState;
  }

  private async handleWriterClick() {
    if (!this.state.writer.memberId || !this.state.writer.slug) {
      throw new Error('No writer memberId/slug provided');
    }

    this.facade.navigateToProfile(
      this.state.writer.memberId,
      this.state.writer.slug,
    );
  }
}
