import { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import { AppData, LikeStatusChangeEvent } from '../../viewer.app';

export type PostCounters = {
  id: string;
  likeCount: number;
  viewCount: number;
  commentsCount: number;
  isLiked: boolean;
};

export class CounterController {
  private state: PostCounters = {
    id: '',
    isLiked: false,
    likeCount: 0,
    viewCount: 0,
    commentsCount: 0,
  };

  private readonly ui = {
    likeStatesContainer: this.$w('#statesContainer'),
    likeCountDefault: this.$w('#likesDefaultNumber'),
    likeButtonEmpty: this.$w('#likesDefaultBlack'),
    likeCountFilled: this.$w('#likesFilledNumber'),
    likeButtonFilled: this.$w('#likesFilledBlack'),
    viewsCount: this.$w('#viewsNumber'),
    commentsCount: this.$w('#commentsNumber'),
  };

  private readonly facade = new BlocksWidgetFacade(this.flowAPI);

  constructor(
    private $w: I$W,
    private flowAPI: PlatformControllerFlowAPI,
    private appData: AppData,
  ) {
    appData?.subjects.likeStatusChanged.subscribe((event) => {
      this.handleLikeChangeEvent(event.data);
    });
  }

  initialize = async (initialState: CounterController['state']) => {
    this.setState(initialState);

    this.ui.likeButtonEmpty.onClick(this.handleLikeClick);
    this.ui.likeButtonFilled.onClick(this.handleLikeClick);
  };

  private async setState(nextState: CounterController['state']) {
    this.state = nextState;
    await this.applyStateToUi();
  }

  private handleLikeClick = async () => {
    if (!this.state.id) {
      throw new Error('No id provided');
    }

    const nextLikeState = !this.state.isLiked;
    const nextLikeCount = this.state.likeCount + (nextLikeState ? 1 : -1);

    if (nextLikeState) {
      await this.facade.likePost(this.state.id);
    } else {
      await this.facade.dislikePost(this.state.id);
    }

    this.setState({
      ...this.state,
      isLiked: nextLikeState,
      likeCount: nextLikeCount,
    });

    this.appData?.subjects.likeStatusChanged.next({
      isLiked: nextLikeState,
      likeCount: nextLikeCount,
    });
  };

  private async applyStateToUi() {
    if (this.state.isLiked) {
      await this.ui.likeStatesContainer.changeState('filled');
      this.ui.likeCountFilled.text = String(this.state.likeCount);
    } else {
      await this.ui.likeStatesContainer.changeState('default');
      this.ui.likeCountDefault.text = String(this.state.likeCount);
    }

    this.ui.viewsCount.text = String(this.state.viewCount);
    this.ui.commentsCount.text = String(this.state.commentsCount);
  }

  private handleLikeChangeEvent(payload: LikeStatusChangeEvent) {
    this.setState({
      ...this.state,
      likeCount: payload.likeCount,
      isLiked: payload.isLiked,
    });
  }
}
